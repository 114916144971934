import React, { useContext, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ContactComponent from '../shared/ContactComponentService'
import { DealerContext } from '../shared/contexts/DealerContext'
import VehicleController from '../ourVehiclesPage/VehicleController';
import { SideBarsContainer } from '@red-build/leadcentre';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0, 6),
  },
  dividerTitle: {
    backgroundColor: "#2e3975",
    width: '33%',
    marginBottom: '0px',
    height: '3px',
    margin: '20px auto'
  },
  bold: {
    fontWeight: 700
  },
  card: {
    margin: "20px 0px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  url: {
    color: '#7c7c7c',
    textDecoration: 'none',
    '&:hover': {
      color: '#1f2532',
      textDecoration: 'none',
    }
  },
  xlbuttonFix: {
    paddingRight: '65px !important',
    fontWeight: 'lighter !important',
    textTransform: 'capitalize !important', backgroundColor: ({ primaryColor }) => `${primaryColor} !important`,
  },
  link: {
    backgroundColor: '#E20A17',
    marginBottom: '1rem',
    color: '#ffffff',
    fontWeight: '700',
    '&:hover': {
      textDecoration: 'none',
      opacity: 0.8
    }
  },
  ServiceHolder: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '@media (max-width: 780px)': {
      flexFlow: 'column',
    }
  }
}));

const Service = () => {
  const { websiteColors } = useContext(DealerContext)
  const classes = useStyles({ ...websiteColors });
  const [groupedMakes, setGroupedMakes] = useState()
  const { globalDealer } = useContext(DealerContext)
  const { vehicles } = VehicleController()

  const findMake = (make) => {

    if (!globalDealer.makes) {
      return;
    }

    let foundMakes = globalDealer.makes.filter(itm => itm.name.toLowerCase() == make.toLowerCase());

    return foundMakes.length > 0;
  }

  useEffect(() => {
    let filteredVehicles = vehicles.filter(itm => findMake(itm.make));
    let makes = groupBy(filteredVehicles, 'make');

    setGroupedMakes(makes);
  }, [vehicles])

  const groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };


  const content = () => {
    return groupedMakes && Object.keys(groupedMakes).map((makeName, index) => {

      let models = groupedMakes[makeName];
      let tmpMake = globalDealer?.makes?.find((m) => {
        return m?.name?.toLowerCase() === makeName?.toLowerCase()
      })

      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item xs={12} sm={12} md={6} key={index} className={classes.card}>
            {tmpMake?.logo && <Box>
              <img className={classes.image} width='200px' height="fit-content" src={tmpMake?.logo} />
            </Box>}
          </Grid>
        </div>
      )
    }
    )
  }

  const title = () => {
    return (
      <Typography gutterBottom variant="h5" align="center" color="textPrimary" fontWeight="500">
        Book a service
      </Typography>

    )
  }

  const subTitle = () => {
    return (
      <Typography gutterBottom variant="h5" align="center" color="textPrimary" fontWeight="500">
        We service the following brands
      </Typography>
    )
  }


  return (
    <Container maxWidth="lg">
      <Box></Box>
      <div className={classes.content}>
        <br />
        <SideBarsContainer
          rightContentTitle={subTitle()}
          leftContent={<ContactComponent leadTypeId={5} />}
          rightContent={content()}
          variant='standard'
          mainTitle={title()}
        />
      </div>
    </Container>
  );
}

export default Service;