import React, { useEffect, useState, useContext } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { ImageContainer } from "../shared/ImageContainer";
import { CurrencyValue } from "../shared/TextMask";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { NavLink as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import VehicleController from "../usedVehicles/VehicleController";
import { useSnackbar } from "notistack";
import { CircularProgress } from "material-ui";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { Modal } from "@material-ui/core";
import CarProfileContainer from "../carProfile/UsedCar";
import UsedVehicles from '../usedVehicles/UsedVehicles';
import { useParams, useHistory } from "react-router-dom";
import { DealerContext } from '../shared/contexts/DealerContext'
import { CompareContext } from '../shared/contexts/CompareContext';
import { FavouriteContext } from '../shared/contexts/FavouriteContext';
import { UsedVehiclesFilter } from '@red-build/leadcentre'

const useStyles = makeStyles((theme) => ({
  filterLoadingContainer: {
    height: "576px",
    position: "absolute",
    width: "210px",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    zIndex: "10",
    boxShadow: "inset 0 0 0 200px rgb(255 255 255 / 5%)",
    filter: "blur(10px)",
  },

  full: {
    width: "93%",
    borderRadius: "3px",

    color: "white!important",
    boxShadow: 'none!important',
    '@media (max-width: 425px)': {
      width: "96%",
    },
    '@media (min-width: 426px) and (max-width: 769px)': {
      width: "97.5%",
    },
    "&:hover": {
      boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    }


  },

  link: {
    fontSize: "0.9rem",
    textTransform: 'capitalize',
  },
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(14, 0, 6),

  },
  dividerTitle: {
    backgroundColor: "#14266e   ",
    width: "33%",
    marginBottom: "30px",
    height: "3px",
    margin: "20px auto",
  },
  carddividerTitle: {
    backgroundColor: "#f39200",
    width: "100%",
    marginBottom: "10px",
    height: "3px",
    margin: "5px auto",
  },
  bold: {
    fontWeight: 700,
  },
  lighter: {
    fontWeight: "lighter",
  },
  flex: {
    display: "flex",
    flexDirection: "row!important",
    justifyContent: "space-between",
  },

  clear: {
    padding: "0px!important",
  },
  price: {
    fontSize: "1.5rem",
    "&>span": {
      fontWeight: 400,
    },
  },
  pricepm: {
    fontSize: "0.8rem",
    "&>span": {
      fontWeight: 400,
    },
  },
  title: {
    fontSize: "1.0rem",
    "&>span": {
      fontWeight: 400,
    },
  },
  vehicleContainer: {
    "& h5, & p": {
      color: "#7c7c7c",
    },
    "&:hover img, &:hover p, &:hover h5": {
      color: "#1f2532",
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  btn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginTop: "2%",
    width: "45%",
  },
  btn1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginTop: "2%",
    width: "45%",
    background: "white",
    border: "1px solid black",
  },
  btn2: {
    minWidth: "100%",
  },
  image: {
    "& > div > div": {
      backgroundColor: "#ffffff !important",
    },
  },
  carsLoadContainer: {
    display: "flex !important",
    justifyContent: "center",

  },
  CarsLoad: {
    display: 'inherit !important',
    margin: 'auto',
    marginTop: '14%'
  },
  flexTop: {
    display: "flex",
    flexDirection: "column!important",
    justifyContent: "space-between",
  },
  center: {
    display: "flex",
    flexDirection: "column!important",
    margin: '0 auto',
  },
  holder: {
    flex: 1,
    display: 'flex',
    justifyContent: 'start',
    '@media (max-width: 426px)': {
      justifyContent: 'end',

    },

  },
  badge: {
    background: 'red',
    color: 'white',
    padding: '0px',
    borderRadius: '50%',
    width: "20px",
    height: '20px',
    display: 'block',
    textAlign: 'center',
    position: 'absolute',
    right: '35px',
    top: '5px',
    fontSize: '0.8rem',
    '@media (max-width: 1024px)': {
      display: 'none',
    },

  },
  badgeimg: {
    width: "75px",

    '@media (min-width: 1025px) and (max-width: 1440px)': {

      width: "50px",
    },
    '@media (min-width: 1441px) and (max-width: 1920px)': {

      width: "50px",
    },
  }

}));

const UsedCarUsedVehiclesPage = () => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { loading, vehicles, total } = VehicleController();
  const [pagedVehicles, setPagedVehicle] = useState(false);
  const [activeVehicle, setActiveVehicle] = useState(null);
  const [activePageIndex, setAcivePageIndex] = useState(0);

  const { websiteColors } = useContext(DealerContext)
  let { compareVal } = useContext(CompareContext)
  let { favouriteVal } = useContext(FavouriteContext)

  let { stockId } = useParams();

  const currentPage = activePageIndex + 1;

  useEffect(() => {

    if (vehicles?.length > 0 && stockId && (activeVehicle?.stockId !== stockId)) {
      var vehicle = vehicles.find((v) => {
        return v.stockId === parseInt(stockId)
      });

      if (vehicle)
        setActiveVehicle(vehicle);
    }

  }, [vehicles]);

  const onSelectVehicle = (vehicle) => {
    history.push(`/stock/${vehicle?.stockId}`)
    setActiveVehicle(vehicle);
  }

  const onGoToPage = (index) => {
    setAcivePageIndex(index);
  }

  const onNextPage = () => {
    if (activePageIndex + 1 >= pagedVehicles.length) return
    setAcivePageIndex(activePageIndex + 1);
    window.scrollTo(0, 200);
  }

  const onPreviousPage = () => {
    if (activePageIndex === 0) return;
    setAcivePageIndex(activePageIndex - 1);
    window.scrollTo(0, 200);
  }

  return (
    <MuiThemeProvider>
      <Container maxWidth="lg" className={classes.content}>
        <Grid className={classes.flexTop}>
          <Typography
            gutterBottom
            variant="h5"
            align="center"
            color="textPrimary"
            fontWeight="500"
          >
            USED TRUCKS
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            align="center"
            color="textPrimary"
            fontWeight="500"
          >
            Discover our large range of used trucks
          </Typography>

          <Divider className={classes.dividerTitle} />
        </Grid>

        <Grid container md={12} spacing={2} >
          {!activeVehicle && !loading && <>
            <Grid md={2} xs={11} >

              <UsedVehiclesFilter
                pageSize={20}
                multiSelect={true}
                websiteColors={websiteColors}
                updateFilteredVehicles={setPagedVehicle}
                vehicles={vehicles}
                total={total}
                isLoading={loading}
              />

              <Box mt={2} className={classes.flex}>
                <Button
                  disabled={loading}
                  gutterBottom
                  variant="contained"
                  color="primary"
                  className={classes.full}

                >
                  <Link variant="button" style={{ color: 'white', }} component={RouterLink} to='/compare'
                    className={classes.link}

                  >
                    <div>
                      {compareVal.length > 0 && <span className={classes.badge}>{compareVal.length}</span>}

                      Compare
                    </div>

                  </Link>
                </Button>
              </Box>

              <Box mt={2} className={classes.flex}>
                <Button
                  disabled={loading}
                  gutterBottom
                  variant="contained"
                  color="primary"
                  className={classes.full}

                >
                  <Link variant="button" style={{ color: 'white', }} component={RouterLink} to='/favourite'
                    className={classes.link}
                  >
                    <div style={{ color: 'black!important' }}>
                      {favouriteVal.length > 0 && <span className={classes.badge}>{favouriteVal.length}</span>}
                      Favourites
                    </div>
                  </Link>
                </Button>
              </Box>

            </Grid>
          </>}

          {loading ? (
            <Grid
              classaName={classes.carsLoadContainer}

              md={12}
            >
              <CircularProgress className={classes.CarsLoad} size={200} />
            </Grid>
          ) : (
            <>
              {
                activeVehicle
                  ? <Grid md={12} xs={12} classaName={classes.flex}> <CarProfileContainer activeVehicle={activeVehicle} /> </Grid>
                  : <Grid md={10} xs={12} classaName={classes.flex}> <UsedVehicles vehicles={pagedVehicles.length > 0 ? pagedVehicles[activePageIndex] : []} onSelectView={onSelectVehicle} />

                    <Grid md={12} >
                      {!activeVehicle &&
                        <div className={classes.btnLoadMoreContainer}>
                          <Box mb={3} className={classes.flex}>
                            <Button
                              variant="contained"
                              gutterBottom
                              className={classes.btn1}
                              onClick={() => onPreviousPage()}
                              disabled={activePageIndex === 0}
                            >
                              {`Previous (${currentPage - 1})`}
                            </Button>
                            <Button
                              variant="contained"
                              gutterBottom
                              className={classes.btn1}
                              onClick={() => onNextPage()}
                              disabled={activePageIndex === (pagedVehicles.length - 1)}
                            >
                              {`Next (page ${currentPage + 1})`}
                            </Button>
                          </Box>
                        </div>
                      }
                    </Grid>
                  </Grid>
              }

            </>
          )}

        </Grid>
      </Container>
    </MuiThemeProvider>
  );
};

export default UsedCarUsedVehiclesPage;
