import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { BASE_URI, WEPAY4CARS_URI, DEALERID } from "./Constants"
import Typography from "@material-ui/core/Typography";
import { DealerContext } from '../shared/contexts/DealerContext';
import { MakeContext } from '../shared/contexts/MakeContext'
import { SellYourVehicle } from '@red-build/leadcentre'

const useStyles = makeStyles((theme) => ({
  root: {


    '@media (max-width: 768px)': {
      paddingTop: '10px'
    }
  },
  stepPadding: {

    marginTop: "2rem",
  },
  stepPaddingButton: {
    marginTop: "2rem",
  },
  card: {
    width: "100%"
  },
  button: {
    width: "100%",

  },
  backButton: {
    marginTop: "1rem",
    width: "100%",

  },
  activebtn: {
    background: "#39679f",
    color: 'white',
    fontWeight: 'bold',
    width: "100%",
    '&:hover': {
      background: '#39679fe6',
    },
  }
}));

export default function TradeInContainer(props) {

  const { makeColors } = useContext(MakeContext)
  const classes = useStyles({ ...makeColors });

  const { globalDealer, websiteColors } = useContext(DealerContext)

  return (
    <Container style={{ marginTop: '50px' }}>
      <Grid item md={12} style={{ textAlign: 'center' }}>
        <Typography align={"center"} variant={"h5"}>
          Please Tell us more about your truck, you wish to trade in.
        </Typography>
      </Grid>

      <SellYourVehicle base_uri={BASE_URI} wePay4Cars_uri={WEPAY4CARS_URI} dealerId={globalDealer.id || DEALERID} websiteColors={websiteColors} leadSourceId={1} />

    </Container>
  )
}
