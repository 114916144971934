import React, { useContext } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useParams } from "react-router-dom";
import { BASE_URI } from '../shared/Constants'
import ApplySteps from '../shared/ApplySteps';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import { DealerContext } from '../shared/contexts/DealerContext'
import { Finance } from '@red-build/leadcentre'

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  divider: {
    backgroundColor: ({ primaryColor }) => `${primaryColor}`,
    width: '33%',
    marginBottom: '30px',
    height: '3px',
    margin: '20px auto'
  },
  success: {
    color: 'green',
    justifyContent: 'center'
  },
  yellow: {
    color: `#2e3975`
  },
  topHeader: {
    fontWeight: 700,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '2.5rem',
    marginBottom: 0
  },
  arrow: {
    width: 0,
    height: 0,
    borderLeft: '30px solid transparent',
    borderRight: '30px solid transparent',
    borderTop: `30px solid #2e3975`,

    margin: '0 auto'
  },
  blue: {
    color: `#2e3975`
  },
  stepsIcon: {
    '& svg': {
      fontSize: '5rem',
      border: ({ primaryColor }) => `2px solid ${primaryColor}`,
      borderRadius: '1rem',
      padding: '0.4rem'
    }
  },
}));

const ApplyFinance = () => {

  const { leadId, colorId } = useParams(false);
  const { websiteColors, globalDealer, dealerList } = useContext(DealerContext)
  const classes = useStyles({ ...websiteColors });

  const stepsState = {
    one: {
      active: true,
      completed: false
    },
    two: {
      active: false,
      completed: false
    },
    three: {
      active: false,
      completed: false
    }
  }

  return (
    <Container maxWidth="lg">
      <div className={classes.content}>
        <Typography gutterBottom variant="h4" fontWeight="500">
          <Box mb={5} className={classes.topHeader}>
            <span className={classes.blue}>Step 1</span> - let's get <span className={classes.blue}>Pre Qualified</span>
          </Box>
        </Typography>
        <Box mb={3}>
          <Typography align="center">
            This step consists of 3 quick steps to ensure the safety of your data. If you need assistance at any point, simply chat to us live 24/7.
          </Typography>
        </Box>
        <Box mb={4}>
          <ApplySteps horizontal disabledStates={stepsState} />
        </Box>
        <Typography gutterBottom variant="h5" align="center" color="textPrimary" fontWeight="500">
          LET'S GO
        </Typography>
        <Box className={classes.arrow}></Box>

        <Grid container alignItems="center" justify="center">

          <Grid item sm={12} md={6}>
            <Box mb={6} mt={4}>
              <Typography align="center" variant="h5">
                Please complete the following details and we will give you a real-time answer
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box style={{ paddingLeft: 16 }}>
              <Box className={classes.stepsIcon}>
                <AllInboxIcon />
              </Box>
              <Typography>
                <span style={{ fontWeight: 700, fontSize: '1.3rem' }}><span className={classes.blue}>STEP 1</span> - Basic Info</span>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            {
              !leadId &&
              <Finance dealerId={globalDealer.id} base_uri={BASE_URI} dealerType={"multi_dealer"} dealers={dealerList} includeValidationChecks={true} pqSuccessUrl={"/pqSuccess/:leadId"} />
            }
          </Grid>

          <Grid item xs={12} container>
            <Grid container>
              <Grid item xs={12} style={{ fontSize: '0.8rem', padding: '0px 25px', textAlign: 'center' }}>
                <span>You will be redirected to MFC for a brief moment to authenticate your session, don't worry you will come right back here. This is a necessary step to protect your information.</span>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </div>
    </Container>
  )
};

export default ApplyFinance;